require('../vendor/bootstrap');

var SignupPage = require('./signup');
var HomePage = require('./home');

window.HomePage = HomePage;

$(function() {
  HomePage.init();
  SignupPage.init();
});