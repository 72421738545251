var _ = require('lodash');

var HomePage = (function() {
  return {
    init: function() {
      if (!$('.homepage').length) return;
      this.initServiceCards();
      this.initIntroVideo();
    },

    initServiceCards: function() {
      if (!Modernizr.touch) return;

      var cards = $('a.homepage__service__text');
      cards.click(function(e) { e.preventDefault(); });
      cards.on('touchstart', function(e) {
        $('.homepage__service').removeClass('focused');
        $(this).closest('.homepage__service').addClass('focused');
      });

      $('.homepage__service__text .homepage__service__more-info').click(function(e) {
        e.stopPropagation();
        var url = $(this).closest('a')[0].href;
        window.location = url;
      });
    },

    initIntroVideo: function() {
      if (!window.$f) return;

      var player = $f($('#intro-player')[0]);
      var playButton = $('.homepage__play__button');
      var modal = $('#intro-video-modal');

      playButton.click(function(e) {
        e.preventDefault();
        modal.modal('show');
        player.api('seekTo', 0);
        player.api('play');
      });

      modal.on('hide.bs.modal', function() {
        player.api('pause');
      });
    }

  };

})();

module.exports = HomePage;