var _ = require('lodash');

var Billing = (function() {
  return {
    elements: {
      ccNumber: '#cc-number',
      ccExp: '#cc-exp',
      ccCvc: '#cc-cvc',
    },

    init: function() {
      this.findElements();

      if (this.$ccNumber.length && this.$ccExp.length && this.$ccCvc.length) {
        this.initFormatters();
      }
    },

    findElements: function() {
      for (var key in this.elements) {
        this['$' + key] = $(this.elements[key]);
        this[key] = this['$' + key].get(0);
      }
    },

    initFormatters: function() {
      this.$ccNumber.payment('formatCardNumber');
      this.$ccExp.payment('formatCardExpiry');
      this.$ccCvc.payment('formatCardCVC');
    },

    createToken: function(cb) {
      var exp = $.payment.cardExpiryVal(this.$ccExp.val());
      var data = {
        number: this.$ccNumber.val(),
        cvc: this.$ccCvc.val(),
        exp_month: exp.month,
        exp_year: exp.year
      };
      Stripe.card.createToken(data, function(status, response) {
        if (response.error) return cb(response.error, response);

        cb(null, response);
      });
    },

    disableCCFields: function() {
      this.$ccNumber.prop('disabled', true);
      this.$ccExp.prop('disabled', true);
      this.$ccCvc.prop('disabled', true);
    },

    validateBilling: function() {
      var areAllValid = true;

      _.each([
        [this.$ccNumber, $.payment.validateCardNumber],
        [this.$ccCvc, $.payment.validateCardCVC],
        [this.$ccExp, function(val) {
          var parsed = $.payment.cardExpiryVal(val);
          return $.payment.validateCardExpiry(parsed.month, parsed.year);
        }]
      ], function(inputValidator) {
        var input = inputValidator[0];
        var validator = inputValidator[1];
        var isValid = validator(input.val() || '');
        input.closest('.form-group').toggleClass('has-error', !isValid);
        areAllValid = areAllValid && isValid;
      });

      return areAllValid;
    },
  };
})();

module.exports = Billing;