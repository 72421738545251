var _ = require('lodash');
var Billing = require('./billing');

var SignupPage = (function() {
  return {
    elements: {
      form: '#signup-form',
      companyNameInput: '#accountNameInput',
      emailAddressInput: '#accountEmailAddressInput',
      phoneInput: '#accountPhoneInput',
      agreeToTermsInput: '#agreeToTerms',
      accountContinueBtn: '#account-continue-btn',
      billingContinueBtn: '#billing-continue-btn',
      loadingOverlay: '.signup-form__loading-overlay',
      csrfToken: 'meta[name=csrf-token]',
      stripeErrorMsg: '#stripeErrorMsg',
      accountErrorMsg: '#accountErrorMsg'
    },

    init: function() {
      this.isLoading = false;

      this.findElements();

      if (!this.form) return;

      Billing.init();
      this.bindEvents();
      this.showScreen('account');
    },

    findElements: function() {
      for (var key in this.elements) {
        this['$' + key] = $(this.elements[key]);
        this[key] = this['$' + key].get(0);
      }
    },

    bindEvents: function() {
      this.$form.submit(_.bind(this.handleSubmit, this));
      this.$accountContinueBtn.click(_.bind(this.accountContinueClicked, this));
      this.$billingContinueBtn.click(_.bind(this.billingContinueClicked, this));
    },

    handleSubmit: function(e) {
      e && e.preventDefault();
      if (!this.validateForm()) return;

      this.setLoadingState(true);

      var self = this;
      Billing.createToken(function(err, response) {
        if (err) {
          self.setLoadingState(false);
          return err;
        } else {
          self.createAccount(response);
        }
      });
    },

    createAccount: function(stripeData) {
      var data = this.$form.serializeArray();
      var self = this;
      data.push({ name: 'stripe_card_token', value: stripeData.id });

      $.ajax({
        type: 'POST',
        url: this.$form.attr('action'),
        dataType: 'json',
        data: data,
        headers: { 'X-CSRFToken': this.$csrfToken.attr('content') },
        success: function(response) {
          self.setLoadingState(false);
          if (response.success) {
            self.showScreen('done');
          }
          else {
            self.showError(response);
          }
        }
      });
    },

    accountContinueClicked: function(e) {
      e && e.preventDefault();
      if (!this.validateAccount()) return;
      this.showScreen('billing');
    },

    billingContinueClicked: function(e) {
      e && e.preventDefault();
      this.$form.submit();
    },

    validateAccount: function() {
      var areAllValid = true;

      _.each([
        this.$companyNameInput,
        this.$emailAddressInput,
        this.$phoneInput,
        this.$agreeToTermsInput
      ], function(input) {
        var isValid;
        if (input.attr('type') === 'checkbox') {
          isValid = input.prop('checked');
        }
        else {
          isValid = (input.val() || '').length > 0;
        }
        input.closest('.form-group').toggleClass('has-error', !isValid);
        areAllValid = areAllValid && isValid;
      });

      return areAllValid;
    },

    validateForm: function() {
      return this.validateAccount() && Billing.validateBilling();
    },

    setLoadingState: function(isLoading) {
      if (this.isLoading === isLoading) return;
      this.isLoading = isLoading;
      this.$loadingOverlay.toggle(isLoading);
      $('.signup-form__continue').prop('disabled', isLoading);
    },

    showError: function(err) {
      if (err.kind === 'stripe') {
        this.$stripeErrorMsg.html(err.msg).removeClass('hidden');
      }
      else if (err.kind === 'email') {
        this.$accountErrorMsg.html(err.msg).removeClass('hidden');
        this.$emailAddressInput.closest('.form-group').addClass('has-error');
        this.showScreen('account');
      }
      else if (err.kind === 'phone') {
        this.$accountErrorMsg.html(err.msg).removeClass('hidden');
        this.$phoneInput.closest('.form-group').addClass('has-error');
        this.showScreen('account');
      }
    },

    showScreen: function(name) {
      var stepActiveClass = 'signup-form__step--active';
      var screenActiveClass = 'signup-form__screen--active';

      $('.' + stepActiveClass).removeClass(stepActiveClass);
      $('.' + screenActiveClass).removeClass(screenActiveClass);
      $('.signup-form__step[data-name="' + name + '"]').addClass(stepActiveClass);
      $('.signup-form__screen[data-name="' + name + '"]').addClass(screenActiveClass);
    }
  };
})();

module.exports = SignupPage;
